import React from 'react';

const UrlSelectForm = (props) => {
  const {
    values,
    selected = '',
    onChange = () => null,
    onSubmit = () => null,
  } = props;

  // Injects the selected value to the callback
  const handleSubmit = () => onSubmit(selected);

  const handleChange = evt => onChange(evt.target.value);

  return <div className="flex items-stretch justify-start leading-6">
    <select
      name="userInfoActions"
      id="userInfoActions"
      className="max-w-full flex-1 bg-gallery p-2 text-lg cursor-pointer rounded-sm border-water border-r-0 border-2 border-solid rounded-r-none leading-3"
      onChange={handleChange}
      value={selected || ''}
    >
      <option value="" disabled>No predefined uri chosen</option>
      {values.map((value, key) => <option
        key={`selectForm-${key}`}
        value={value.url}>{value.label}</option>)}
    </select>

    <button onClick={handleSubmit} className="p-2 px-4 w-fit bg-matisse text-gallery border-matisse border-solid border-2">Retrieve</button>
  </div>;
};

export default UrlSelectForm;
