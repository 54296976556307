import React from 'react';
import { MultilineCodeBlock } from '../../features/ui/code';
import { prettyPrintJson } from '../../util/stringUtil';

/**
 * Null-friendly component, which pretty-prints JSON data
 * */
const JsonCodeBlock: React.FC<{ json?: Record<string, any> }> = ({ json = undefined }) => {
  if (!json) {
    return <pre/>;
  }

  const serialized = prettyPrintJson(json);

  return <MultilineCodeBlock>{serialized}</MultilineCodeBlock>;
};

export default JsonCodeBlock;
