import React from 'react';
import JsonCodeBlock from '../ApiResponseCard/JsonCodeBlock';

const GenericErrorCard = (props) => {
  const {
    title = null,
    message = null,
    json = null,
    ...rest
  } = props;

  return <div {...rest} className="message is-danger">
    {title && <div className="items-center flex font-bold justify-between p-3 px-4 relative bg-coral text-gallery leading-5">
      <p>{title}</p>
    </div>}
    <div className="border-none p-5 px-6 bg-[#fef6f6]">
      <div className="text-[#c21e1e]">{message}</div>
      {json && <JsonCodeBlock json={json}/>}
    </div>
  </div>;
};

export default GenericErrorCard;
