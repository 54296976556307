import qs from 'qs';
import { generateNonce, persistNonce } from './cryptoUtil';

// Fetched from https://docs.feide.no/developer_oauth/technical_details/openidconnect.html#supported-features
export const OAUTH_VALID_RESPONSE_TYPES = ['token', 'id_token token'];

const buildAuthorizationParams = (redirectUri, clientId, responseType = 'token', scopes) => {
  if (!OAUTH_VALID_RESPONSE_TYPES.includes(responseType)) {
    throw new Error(`Expected responseType to be if either ${OAUTH_VALID_RESPONSE_TYPES.join(', ')}, instead got ${responseType}`);
  }

  return {
    client_id: clientId,
    response_type: responseType,
    redirect_uri: redirectUri,
    scope: scopes.join(' '),
  };
};

/**
 * Builds the OAuth authorization url.
 * */
const buildOauthAuthorizationUrl = (endpoint, query) => `${endpoint}?${qs.stringify(query)}`;


/**
 * Simply redirects the window to the external page,
 * with our custom url.
 * */
// eslint-disable-next-line import/prefer-default-export
export const visitClientAuthorization = (authorizationEndpoint, redirectUri, clientId, responseType = 'token', scopes = []) => {
  const query = buildAuthorizationParams(redirectUri, clientId, responseType, scopes);

  if (responseType === OAUTH_VALID_RESPONSE_TYPES[1]) {
    const nonce = generateNonce();
    persistNonce(nonce);

    query.nonce = nonce;
  }

  window.location.href = buildOauthAuthorizationUrl(authorizationEndpoint, query);
};

/**
 * @param {object} clientAuth The clientAuth state
 * @return {boolean} True if accessToken was in state, false otherwise
 * */
export const hasAccessToken = clientAuth => (
  clientAuth.accessToken && !!clientAuth.accessToken.accessToken
);
