import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '../components/OauthConnectionForm/TextField';
import useRetrieveOpenIdConfigurations from '../features/openidconfiguration/useRetrieveOpenIdConfigurations';
import { InlineCode } from '../features/ui/code';
import OauthPlayFrame from '../features/ui/OauthPlayFrame';
import { HelpText } from '../features/ui/form';
import { PageTitle } from '../features/ui/text';
import useOauthAuthenticationConfiguration from '../hooks/useOauthAuthenticationConfiguration';

const DiscoverHostnamePage = () => {
  const navigate = useNavigate();
  const configuration = useOauthAuthenticationConfiguration();
  const [hostname, setHostnameInput] = useState(configuration.getBaseUrl() ?? '');
  // Load the data in the background
  useRetrieveOpenIdConfigurations(hostname);

  const onSubmit = (evt) => {
    evt.preventDefault();
    navigate(`/configure/?hostname=${encodeURIComponent(hostname)}`);
  };

  const updateHostname = (event) => {
    const hostname = event.target.value;
    setHostnameInput(hostname);
  }

  return (
    <OauthPlayFrame>
      <section>
        <PageTitle>OAuth Server Hostname</PageTitle>
        <HelpText>
          Fill in the hostname of your OAuth server.
          Will only work if your OAuth server support OpenID Connect Discovery (has the
          endpoint <InlineCode>/.well-known/openid-configuration</InlineCode>).
          If not, please enter configuration manually on the next page.
        </HelpText>
      </section>

      <form className="my-4" onSubmit={onSubmit}>
        <TextField
          type="url"
          name="serverHostname"
          value={hostname}
          placeholder="https://auth.dataporten.no"
          onChange={updateHostname}
          autoFocus={true}
          required={true}
        />

        <button type="submit" className="p-2 px-4 w-full bg-matisse text-gallery">Discover</button>
      </form>
    </OauthPlayFrame>
  );
};

export default DiscoverHostnamePage;
