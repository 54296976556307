import { QueryClientProvider } from '@tanstack/react-query';
import moment from 'moment/moment';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { queryClient } from './client';

import App from './components/App';
import { AuthenticationProvider } from './context/authenticationContext';
import { getSentry } from './util/sentry';

import './style/globals.css';

// Override any user preferences in browser
moment.locale('en');

getSentry();

const root = createRoot(document.getElementById('root')!);

root.render(
  <QueryClientProvider client={queryClient}>
    <AuthenticationProvider>
      <App/>
    </AuthenticationProvider>
  </QueryClientProvider>,
);
