import React from 'react';
import { MultilineCodeBlock } from '../../features/ui/code';
import { ButtonLink } from '../../features/ui/link';
import { prettyPrintJson } from '../../util/stringUtil';
import GenericErrorCard from '../GenericErrorCard';
import OauthPlayFrame from '../../features/ui/OauthPlayFrame';

const OauthErrorFrame = (props) => {
  const { returnUrl, header, error } = props;

  return <OauthPlayFrame>
    <section>
      <h2 className="font-bold leading-5 text-3xl block mb-6">{header}</h2>
      <ButtonLink to={returnUrl.url} className="block w-full">{returnUrl.label}</ButtonLink>
      {error && <div className="mt-8">
        <GenericErrorCard
          title={error.title}
          message={<MultilineCodeBlock>{prettyPrintJson(error.originalError)}</MultilineCodeBlock>}
        />
      </div>}
    </section>
  </OauthPlayFrame>;
};

export default OauthErrorFrame;
