const NONCE_KEY = 'nonce';

/**
 *
 * Reference: https://auth0.com/docs/api-auth/tutorials/nonce#generate-a-cryptographically-random-nonce
 * */
function randomString(preferredLength) {
  let length = preferredLength;
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
  let result = '';

  while (length > 0) {
    const bytes = new Uint8Array(16);
    const random = window.crypto.getRandomValues(bytes);

    // eslint-disable-next-line no-loop-func
    random.forEach((char) => {
      if (length === 0) {
        return;
      }

      if (char < charset.length) {
        result += charset[char];
        length -= 1;
      }
    });
  }
  return result;
}

export const generateNonce = () => randomString(15);

export const persistNonce = (nonce) => {
  localStorage.setItem(NONCE_KEY, nonce);
};

// export const retrieveNonce = () => localStorage.getItem(NONCE_KEY);
// export const purgeNonce = () => localStorage.removeItem(NONCE_KEY);
