
export const OPENIDCONNECT_STANDARD_SCOPE = 'openid';

export const hasOpenIdConnectScope = scopes => scopes.includes(OPENIDCONNECT_STANDARD_SCOPE);

/**
 *
 * @param {object} openidConfiguration Contents from /.well-known/openid-configuration
 * @return {object} On the form { userinfoEndpoint: '', jwksUri: '' }
 * */
export const getOpenIdConnectUrls = (openidConfiguration) => {
  const urls = [];

  if (openidConfiguration.userinfoEndpoint) {
    urls.push({
      url: openidConfiguration.userinfoEndpoint,
      label: 'OpenID Userinfo endpoint',
    });
  }

  return urls;
};
