import classNames from 'classnames';
import React from 'react';

/**
 * Represents a <code> element that can be used inline in text
 * */
export const InlineCode: React.FC<React.HTMLProps<any>> = ({ children, className = undefined }) => {
  return <code
    className={classNames('bg-alabaster text-coral font-medium p-1 px-2', className)}
  >{children}</code>;
};

export const CodeOneLineBlock: React.FC<React.HTMLProps<any>> = ({ children, className = undefined }) => {
  return <code className={classNames('block w-fit whitespace-nowrap leading-6 p-1 px-2 bg-[#eeeeee] text-mine-shaft', className)}>{children}</code>;
}

export const MultilineCodeBlock: React.FC<React.HTMLProps<any>> = ({ children, className = undefined }) => {
  return <pre
    className={classNames('my-4 bg-gallery m-0 p-4 font-monospace text-mine-shaft overflow-x-scroll whitespace-pre break-normal')}
  >{children}</pre>;
};
