import React from 'react';
import { CodeOneLineBlock, InlineCode } from '../../features/ui/code';
import { HelpText, InputLabel, InputWrapper } from '../../features/ui/form';
import { Anchor, CustomLink } from '../../features/ui/link';
import TextField from './TextField';

/**
 * Form used to configure the OAuth 2.0 connection.
 * The form is built to work directly with Formik.
 * */
const OauthConnectionForm = (props) => {
  const {
    accessToken,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    callbackUri,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className="m-8 mx-0">
        <legend className="text-2xl font-medium m-0">Discovered OAuth 2.0 connection options</legend>
        <HelpText className="mb-4">
          Most of these fields are automatically discovered by
          reading the server`s <InlineCode>/.well-known/openid-configuration</InlineCode>
          endpoint. Therefore, it should not be necessary to modify these values.
        </HelpText>

        <TextField
          name="authorizationEndpoint"
          label="OAuth Authorization endpoint"
          value={values.authorizationEndpoint}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <TextField
          name="tokenEndpoint"
          label="OAuth Token endpoint"
          value={values.tokenEndpoint}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <TextField
          name="userinfoEndpoint"
          label="OAuth Userinfo endpoint"
          value={values.userinfoEndpoint}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </fieldset>

      <fieldset className="m-8 mx-0">
        <legend className="text-2xl font-medium m-0 mb-4">Configure your OAuth client</legend>

        <TextField
          name="clientId"
          label="Client ID"
          placeholder="4232afdf-f38a-4512-9b1b-f35c3cd92374"
          value={values.clientId}
          onBlur={handleBlur}
          onChange={handleChange}
          minLength={8}
          maxLength={255}
        />

        <TextField
          name="scopes"
          label="Scopes"
          help={<HelpText>
            Scopes define what data you want to retrieve from a user
            (<Anchor href="https://oauth.net/2/scope/" target="_blank" rel="noopener noreferrer">OAuth - Scopes</Anchor>).
            Types of scope differ from service to service.
            Example from Feide: <em>openid, profile, email</em>
          </HelpText>}
          placeholder="name, email"
          value={values.scopes}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <InputWrapper className="mb-2">
          <InputLabel htmlFor="responseType">Response type</InputLabel>
          <HelpText>
            See description in&nbsp;
            <Anchor href="https://docs.feide.no/developer_oauth/technical_details/openidconnect.html#supported-features"
               target="_blank"
               rel="noopener noreferrer"
            >feide docs</Anchor>
          </HelpText>
          <InputWrapper className="mb-2">
            <select
              name="responseType"
              id="responseType"
              className="max-w-full bg-gallery p-2 hover:cursor-pointer rounded-sm border-water border-2 border-solid"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.responseType}
            >
              <option value="id_token token">OpenID Connect Implicit Flow (id_token token)</option>
              <option value="token">OAuth Implicit grant (token)</option>
            </select>
          </InputWrapper>
        </InputWrapper>

        <InputWrapper>
          <strong className="text-mine-shaft font-bold m-0 mb-[5px]">Redirect Uri</strong>
          <p>When you configure your client, please register the following redirect uri:</p>
          <p><CodeOneLineBlock>{callbackUri}</CodeOneLineBlock></p>
        </InputWrapper>
      </fieldset>

      <div className="m-2 mx-0">
        {accessToken && <div className="bg-water p-5 my-3 text-tundora">
          You already have an access token available.
          Do you wish to skip past this configuration? <CustomLink className="border-b-boulder" to="/accesstoken">Jump to Access token</CustomLink>
        </div>}
        <button
          type="submit"
          className="p-2 px-4 w-full bg-matisse text-gallery"
          disabled={isSubmitting}
        >
          {accessToken ? 'Re-authenticate' : 'Authenticate'}
        </button>
      </div>
    </form>
  );
};

export default OauthConnectionForm;
