import React from 'react';
import { InlineCode } from '../../features/ui/code';

interface HttpRequestPreviewProps {
  readonly method: 'GET';
  readonly uri: string;
  readonly onUriChange: (uri: string) => void;
}

const HttpRequestPreview: React.FC<HttpRequestPreviewProps> = ({ method, uri, onUriChange }) => {
  const handleChange = (evt) => {
    onUriChange(evt.target.value);
  };

  return <div className="w-full">
    <InlineCode>{method}</InlineCode> request to
    <input
      className="block leading-3 justify-start font-normal w-full p-2.5 border-2 border-solid rounded-sm border-[transparent] border-b-water bg-gallery mt-1 mb-0
                 hover:border-b-turquoise focus:border-b-turquoise hover:outline-0 focus:outline-0"
      value={uri}
      onChange={handleChange}
    />
  </div>;
};

export default HttpRequestPreview;
