import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const openIdSuffix = '.well-known/openid-configuration';

const getOpenIdEndpoint = (host: string): string => {
  let formattedHost = host;

  if (!formattedHost.endsWith('/')) {
    formattedHost = `${formattedHost}/`;
  }

  return `${formattedHost}${openIdSuffix}`;
};

/**
 * camelCase version of the relevant attributes we can find on /.well-known/openid-configuration
 * */
export interface OpenIdConfiguration {
  readonly authorizationEndpoint: string;
  readonly claimsSupported: string[];
  readonly userinfoEndpoint: string;
  readonly tokenEndpoint: string;
  readonly responseTypesSupported: string[];
  readonly scopesSupported: string[];
  readonly codeChallengeMethodsSupported: string[];
  readonly endSessionEndpoint?: string;
}

const fetchOpenIdConfigurationForHost = (host: string): Promise<OpenIdConfiguration> => {
  const uri = getOpenIdEndpoint(host);

  return axios
    .get(uri)
    // Map relevant attributes over to the attributes we want and in correct format
    .then(({ data }: { data: Record<string, any> }) => ({
      authorizationEndpoint: data.authorization_endpoint,
      claimsSupported: data.claims_supported as string[],
      userinfoEndpoint: data.userinfo_endpoint,
      tokenEndpoint: data.token_endpoint,
      responseTypesSupported: data.response_types_supported as string[],
      scopesSupported: data.scopes_supported as string[],
      codeChallengeMethodsSupported: data.code_challenge_methods_supported as string[],
      endSessionEndpoint: data.end_session_endpoint,
    }));
}

interface UseRetrieveOpenIdConfigurationsResult {
  readonly openIdConfigurations?: OpenIdConfiguration;
  readonly isLoading: boolean;
  readonly error?: Error;
}

const useRetrieveOpenIdConfigurations = (hostname: string): UseRetrieveOpenIdConfigurationsResult => {
  const { data, error, isLoading } = useQuery<OpenIdConfiguration, Error>(
    ['openidconfiguration', hostname],
    () => fetchOpenIdConfigurationForHost(hostname),
    {
      enabled: !!hostname && hostname.length > 3 && hostname.startsWith('http'),
      onError(error) {
        console.error(`Failed to retrieve OpenId configurations for host: ${hostname}`, error);
      }
    }
  );

  return {
    isLoading,
    openIdConfigurations: data,
    error: error ?? undefined,
  }
};

export default useRetrieveOpenIdConfigurations;
