import React from 'react';
import moment, { Moment } from 'moment/moment';

// Override any user preferences in browser
moment.locale('en');

/**
 * General purpose notification bar to show when access token has expired
 * */
const AccessTokenExpirationNotification: React.FC<{ expiresOn: Moment }> = ({ expiresOn }) => {
  const hasExpired = expiresOn.isSameOrBefore(moment());
  const expiresIn = expiresOn.fromNow();

  if (!hasExpired) {
    return null;
  }

  return <p className="bg-peach text-mine-shaft p-5 px-7 rounded-sm mb-4">Access token expired {expiresIn}</p>;
};

export default AccessTokenExpirationNotification;
