import React from 'react';
import { ErrorMessage } from 'formik';
import { InputLabel, InputWrapper } from '../../features/ui/form';

const TextField = (props) => {
  const {
    name,
    label,
    help = null,
    ...values
  } = props;

  return <InputWrapper>
    <InputLabel htmlFor={`id-${name}`}>{label}</InputLabel>
    {help}
    <input
      {...values}

      id={`id-${name}`}
      name={name}
      className="block justify-start w-full p-2 leading-3 border-2 border-solid rounded-sm border-[transparent] border-b-water bg-gallery m-0 mb-0
                 hover:border-b-turquoise focus:border-b-turquoise hover:outline-0 focus:outline-0"
      type="text"
    />

    <ErrorMessage name={name} component="p" className="block p-0 px-0 text-coral"/>
  </InputWrapper>;
};

export default TextField;
