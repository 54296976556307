const apiGateKeeperMatcher = /^gk_([^_]+)$/i;

// Any links which should by default be included
const predefinedLinks = [
  {
    url: 'https://auth.dataporten.no/userinfo',
    label: 'Basic userinfo',
  },
];

const isGroupsScope = (scope) => {
  return scope.startsWith('groups-');
};

/**
 * Note: Use only when you know that you communicate with the
 * Dataporten/Feide OAuth API.
 *
 * Based on the token's scope, attempt to build a list of
 * legal API endpoints, which the user can query.
 *
 * @return {array} List of API endpoints
 * */
export function getFeideApiUrls(scopes): { url: string; label: string }[] {
  const identifiedLinks: Record<string, { url: string; label: string }> = {};

  scopes.forEach((scope) => {
    if (isGroupsScope(scope)) {
      // This scope give the user access to groups
      identifiedLinks.groups = {
        url: 'https://groups-api.dataporten.no/groups/me/groups',
        label: 'Feide groups API: My groups',
      };
    }

    // Attempt to find any APIs by its scope-name
    const match = apiGateKeeperMatcher.exec(scope);

    if (match) {
      identifiedLinks.gatekeeper = {
        url: `https://${match[1]}.dataporten-api.no/`,
        label: `Feide APIGK ${match[1]}`,
      };
    }
  })

  return [...predefinedLinks, ...Object.values(identifiedLinks)];
}
