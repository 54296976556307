import React from 'react';

import logo from './horisontal-feide.svg';

const Logo = () => <div className="items-end flex justify-start">
  <img src={logo} alt="Feide logo" className="my-0 h-[45px]"/>
  <h1 className="ml-3 my-0 text-2xl leading-5 text-mine-shaft">OAuth 2.0 Play</h1>
</div>;

export default Logo;
