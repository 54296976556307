import React from 'react';
import qs from 'qs';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import useRetrieveOpenIdConfigurations, { OpenIdConfiguration } from '../features/openidconfiguration/useRetrieveOpenIdConfigurations';
import { PageTitle } from '../features/ui/text';
import useAccessToken from '../hooks/useAccessToken';
import useOauthAuthenticationConfiguration, { OauthAuthenticationConfiguration } from '../hooks/useOauthAuthenticationConfiguration';
import OauthPlayFrame from '../features/ui/OauthPlayFrame';
import { visitClientAuthorization } from '../util/authorizationUtil';
import OauthConnectionForm from '../components/OauthConnectionForm';
import { getCallBackUri } from '../util/hostUtil';
import { OPENIDCONNECT_STANDARD_SCOPE } from '../util/oauth/openIdConnectHelper';
import { isValidHttpUrl, listifyCsv } from '../util/stringUtil';

const getConfigFromUrl = (location) => {
  const query = qs.parse(location.search.replace('?', ''));
  return query.hostname || null;
};

const callbackUri = getCallBackUri();

const authenticateClient = (values) => {
  const scopes = values.scopes ? listifyCsv(values.scopes) : [];

  visitClientAuthorization(
    values.authorizationEndpoint,
    callbackUri,
    values.clientId,
    values.responseType,
    scopes,
  );
};

/**
 * Selects the most appropriate values to initially insert into the form,
 * hopefully making the process simpler for the user.
 *
 * Data persisted in the browser is preferred, then data retrieved from the identity provider is second.
 * Lastly, default values are used
 *
 * @return All properties populated. Missing values are an empty string instead of undefined
 * */
const determineInitialValues = (
  persisted?: OauthAuthenticationConfiguration,
  identityProviderDefaults?: OpenIdConfiguration,
): Required<OauthAuthenticationConfiguration> => {
  const selectTruthyValue = (preferred?: string, fallback?: string): string|undefined => {
    if (!!preferred && preferred.length > 0) {
      return preferred;
    }
    return fallback;
  }

  return {
    // These attributes can have been modified by the user. If not, then see if the OAuth Identity Provider had some defaults
    authorizationEndpoint: selectTruthyValue(persisted?.authorizationEndpoint, identityProviderDefaults?.authorizationEndpoint) || '',
    tokenEndpoint: selectTruthyValue(persisted?.tokenEndpoint, identityProviderDefaults?.tokenEndpoint) || '',
    userinfoEndpoint: selectTruthyValue(persisted?.userinfoEndpoint, identityProviderDefaults?.userinfoEndpoint) || '',

    clientId: persisted?.clientId || '',
    scopes: persisted?.scopes || '',
    responseType: persisted?.responseType || 'id_token token',
  }
};

const Configuration = () => {
  const location = useLocation();
  // Persist the configured values, so it is easier to return to the site
  const { configuration: persistedConfiguration, saveConfiguration } = useOauthAuthenticationConfiguration();
  const accessToken = useAccessToken();

  const hostname = getConfigFromUrl(location);
  const {
    openIdConfigurations,
  } = useRetrieveOpenIdConfigurations(hostname);

  const initialValues = determineInitialValues(persistedConfiguration, openIdConfigurations);

  return (
    <OauthPlayFrame>
      <section>
        <PageTitle>Configure</PageTitle>
        {hostname && <p className="text-sm">
          <span className="bg-water p-1 px-2 text-matisse rounded-sm">Host:&nbsp; <strong className="text-matisse">{hostname}</strong></span>
        </p>}
      </section>

      <Formik
        // Ensures that the form is updated when the
        // openId configuration props is updated
        enableReinitialize={true}
        initialValues={initialValues}

        validate={(values) => {
          saveConfiguration(values);

          const errors: Record<string, string> = {};

          if (!isValidHttpUrl(values.authorizationEndpoint)) {
            errors.authorizationEndpoint = 'Authorization endpoint has to be a valid http url';
          }

          if (values.tokenEndpoint && !isValidHttpUrl(values.tokenEndpoint)) {
            errors.tokenEndpoint = 'Token endpoint has to be a valid http url';
          }

          if (!isValidHttpUrl(values.userinfoEndpoint)) {
            errors.userinfoEndpoint = 'Userinfo endpoint has to be a valid http url';
          }

          if (!values.clientId || values.clientId.length < 4) {
            errors.clientId = `Client ID has to be at least 4 characters. Yours is ${values.clientId.length} characters`;
          }

          const scopes = listifyCsv(values.scopes);

          // Give hints to user when OpenID Connect is selected, but expected scopes are missing
          if (values.responseType === OPENIDCONNECT_STANDARD_SCOPE && !scopes.includes('openid')) {
            errors.scopes = `When response type is OpenID Connect,
              must you include scope '${OPENIDCONNECT_STANDARD_SCOPE}'. Current scopes are: ${scopes.join(', ')}`;
          }

          return errors;
        }}

        onSubmit={authenticateClient}
      >
        {params => <OauthConnectionForm
          {...params}
          callbackUri={callbackUri}
          accessToken={accessToken.accessToken}/>}
      </Formik>
    </OauthPlayFrame>
  );
};

export default Configuration;
