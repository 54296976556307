import React from 'react';
import { Anchor } from '../../features/ui/link';

const getCurrentYear = () => {
  const now = new Date();

  return now.getFullYear();
};

const Footer: React.FC = () => <footer className="block bg-alabaster p-10 px-6 pb-24">
  <div className="text-center leading-8">
    <p>This site is provided by</p>
    <p><Anchor href="https://sikt.no/" target="_blank" rel="noopener noreferrer">Sikt - Norwegian Agency for Shared Services in Education and Research</Anchor> ({getCurrentYear()})</p>
  </div>
</footer>;

export default Footer;
