import { useEffect, useState } from 'react';

const readPersistedData = (persistenceKey: string): string|undefined => {
  try {
    return localStorage.getItem(persistenceKey) ?? undefined;
  } catch (error) {
    console.error(`Failed to read item (${persistenceKey}) from localStorage`, error);
    return undefined;
  }
};

/**
 * Minimal utility-hook that can persist data in the browser,
 * and read already persisted information.
 * */
const usePersistData = <T> (persistenceKey: string) => {
  // This state is used as an intermediary between the persistence function and localStorage.
  // We also use the persisted variable as a signature to compare existing data with the old.
  // This is used to avoid unnecessary writes to localStorage
  const [persisted, setPersisted] = useState<string|undefined>(readPersistedData(persistenceKey));

  useEffect(() => {
    // Update the state in case someone finds out that they want to
    // change the persistence key (even thought it should be static)
    setPersisted(readPersistedData(persistenceKey));
  }, [persistenceKey]);

  useEffect(() => {
    if (!persisted) {
      return;
    }
    localStorage.setItem(persistenceKey, persisted);
  }, [persistenceKey, persisted]);

  return {
    persist(data: T) {
      const serialized = JSON.stringify(data);
      if (persisted === serialized) {
        // The data is identical. No need to write to localStorage
        return;
      }

      setPersisted(serialized);
    },

    getPersistedData(): T|undefined {
      if (!persisted) {
        return undefined;
      }

      return JSON.parse(persisted);
    }
  }
};

export default usePersistData;
