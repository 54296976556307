/**
 * Pretty prints JSON, using JSON.stringify's native pretty-print.
 *
 * @param {object} data JSON data, un-serialized
 * @param {number} space Amount of spacing in indendation
 * @return {string} Pretty printed JSON
 * */
// eslint-disable-next-line import/prefer-default-export
export const prettyPrintJson = (data, space = 2) => JSON.stringify(data, null, space);

/**
 * Forces HTTP or HTTPS protocol to a url
 *
 * @param {string} uri
 * @return {string}
 */
const forceProtocolPrefix = (uri) => {
  if (!uri.startsWith('https') && !uri.startsWith('http')) {
    return `https://${uri}`;
  }

  return uri;
};

const stripHostname = (url) => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);

  if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
    return match[2];
  }

  return '';
};

export const equalsHostname = (hostname1, hostname2) => {
  const stripped1 = stripHostname(forceProtocolPrefix(hostname1));
  const stripped2 = stripHostname(forceProtocolPrefix(hostname2));

  return stripped1 === stripped2;
};

/**
 * Ref: https://stackoverflow.com/a/5717133/4338833
 *
 * Does a simple check if some url is valid. Thorough
 * tests are difficult and resource demanding,
 * such that this focuses more on hitting 'true positives'
 * rather than detecting all 'false negatives', because
 * these will fail on when a url is called.
 *
 * @param {string} str
 * @return {boolean} true => most likelly valid, false => probably invalid
 * */
export function isValidHttpUrl(str) {
  // No point in running regex on an empty string
  if (!str || str.length < 1) {
    return false;
  }

  try {
    // Will flag httpsss://google.com as valid
    return Boolean(new URL(str));
  } catch (err) {
    return false;
  }
}

export function listifyCsv(line) {
  if (!line) {
    return [];
  }

  return line.split(',')
    .map(word => word.trim())
    .filter(word => !!word);
}
