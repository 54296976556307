import React from 'react';
import JsonCodeBlock from './JsonCodeBlock';
import HttpRequestPreview from './HttpRequestPreview';
import GenericErrorCard from '../GenericErrorCard';

/**
 * Card used to display responses from a
 * API endpoint, which returns JSON-data.
 * */
const ApiResponseCard = (props) => {
  const {
    data = null,
    requestUri = null,
    error = null,
    onUriChange,
  } = props;

  return <div className="text-mine-shaft max-w-full relative">
    <div className="flex items-stretch">
      <div className="flex flex-grow font-bold pb-3 items-center">
        {requestUri
          ? <HttpRequestPreview method="GET" uri={requestUri} onUriChange={onUriChange}/>
          : <h3>Response data</h3>}
      </div>
    </div>
    <div className="py-4">
      {!error
        ? <JsonCodeBlock json={data}/>
        : <GenericErrorCard title="API Error" message={error.message} json={error.data || null}/>}
    </div>
  </div>;
};

export default ApiResponseCard;
