import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// eslint-disable-next-line import/no-named-as-default
import Header from './Header';

import DiscoverHostnamePage from '../pages/DiscoverHostnamePage';
import Configuration from '../pages/Configuration';
// eslint-disable-next-line import/no-named-as-default
import OauthPlayPanel from './OauthPlayPanel';

import CallbackPage from '../pages/CallbackPage';
import PreviewAccessToken from '../pages/PreviewAccessToken';
import UserInfo from '../pages/UserInfo';
import Footer from './Footer';
// eslint-disable-next-line import/no-named-as-default
import OuterErrorBoundary from './OuterErrorBoundary';

const RedirectToHome = () => {
  return <Navigate to="/discover"/>;
};

const App = () => (
  <OuterErrorBoundary>
    <BrowserRouter>
      <div className="bg-alabaster flex flex-col items-stretch justify-start min-h-screen">
        <Header/>
        <div className="m-auto max-w-6xl w-full flex flex-wrap flex-1">
          <OauthPlayPanel/>
          <main className="basis-3/4 flex-1">
            <Routes>
              <Route path="/discover" element={<DiscoverHostnamePage/>}/>
              <Route path="/configure" element={<Configuration/>}/>
              <Route path="/callback" element={<CallbackPage/>}/>
              <Route path="/accesstoken" element={<PreviewAccessToken/>}/>
              <Route path="/userinfo" element={<UserInfo/>}/>

              <Route path="*" element={<RedirectToHome/>}/>
            </Routes>
          </main>
        </div>
        <Footer/>
      </div>
    </BrowserRouter>
  </OuterErrorBoundary>
);

export default App;
