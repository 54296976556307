import React from 'react';
import AccessTokenExpirationTag from './AccessTokenExpirationTag';

/**
 * StatusPanel containing common user actions and/or statuses
 * relevant for the user
 * */
const StatusPanel = ({ onClearToken, hasAccessToken, expiresOn }) => {
  return <div className="flex items-center justify-end">
    <AccessTokenExpirationTag expiresOn={hasAccessToken ? expiresOn : undefined}/>
    <button
      className="bg-coral text-gallery cursor-pointer justify-center items-center text-center whitespace-nowrap p-1.5 px-4 rounded-sm"
      onClick={onClearToken}
      disabled={!hasAccessToken}
    >Clear Token</button>
  </div>;
};

export default StatusPanel;
