import classNames from 'classnames';
import React from 'react';
import moment from 'moment/moment';

/**
 * Expiration time
 * */
const AccessTokenExpirationTag = ({ expiresOn, className = undefined }) => {
  if (!expiresOn) {
    return null;
  }

  const hasExpired = expiresOn.isSameOrBefore(moment());
  const expiresIn = expiresOn.fromNow();

  const text = hasExpired ? `Token expired ${expiresIn}` : `Token expires ${expiresIn}`;

  return <em
    className={classNames(
      'bg-silver leading-5 whitespace-nowrap p-2 px-4 mr-2 rounded-sm not-italic',
      { 'bg-peach': hasExpired },
      className,
    )}
  >{text}</em>;
};

export default AccessTokenExpirationTag;
