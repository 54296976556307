import { Navigate } from 'react-router-dom';
import useOauthAuthenticationConfiguration from '../../hooks/useOauthAuthenticationConfiguration';

export const buildConfigureUrl = (authorizationEndpoint?: string) => {
  if (!authorizationEndpoint) {
    return undefined;
  }

  const baseUrl = new URL(authorizationEndpoint).origin;

  return `/configure/?hostname=${encodeURIComponent(baseUrl)}`;
}

/**
 * Utility redirect component.
 * Will redirect the user to the OAuth configuration page
 * with the previously used Identity provider.
 * */
const RedirectToConfigurationPage = () => {
  const { configuration } = useOauthAuthenticationConfiguration();

  const url = buildConfigureUrl(configuration?.authorizationEndpoint);

  if (!url) {
    return <Navigate to="/discover"/>;
  }

  return <Navigate to={url}/>
};

export default RedirectToConfigurationPage;
