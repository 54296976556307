import moment, { Moment } from 'moment/moment';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../context/authenticationContext';
import useOauthAuthenticationConfiguration from './useOauthAuthenticationConfiguration';

interface UseAccessTokenResult {
  accessToken?: string;
  expiresOn: Moment,
  rawResponse?: Record<string, any>,

  saveToken: (accessToken: string, expiresOn: Moment, rawResponse: Record<string, any>) => void;
  clearToken: () => void;
}

/**
 * Wrapper around the persisted access token
 * */
const useAccessToken = (): UseAccessTokenResult => {
  const { state, dispatch } = useContext(AuthenticationContext);

  return {
    accessToken: state.accessToken,
    expiresOn: state.expiresOn,
    rawResponse: state.rawResponse,

    saveToken(accessToken: string, expiresOn: Moment, rawResponse: Record<string, any>) {
      dispatch({
        accessToken,
        rawResponse,
        expiresOn,
        type: 'STORE_ACCESS_TOKEN',
      })
    },
    clearToken() {
      dispatch({ type: 'CLEAR_ACCESS_TOKEN' });
    }
  }
};

export default useAccessToken;

/**
 * More strict version of useAccessToken. This hook requires a valid access token,
 * otherwise the user is redirected.
 * */
export const useRequiredAccessToken = (): Required<UseAccessTokenResult> => {
  const tokenConfiguration = useAccessToken();
  const navigate = useNavigate();
  const oauthConfiguration = useOauthAuthenticationConfiguration();

  const hasExpired = tokenConfiguration.expiresOn.isSameOrBefore(moment());

  if (!tokenConfiguration.accessToken || hasExpired) {
    const authorizationEndpoint = oauthConfiguration.configuration?.authorizationEndpoint
      ? new URL(oauthConfiguration.configuration.authorizationEndpoint)
      : undefined;

    if (authorizationEndpoint) {
      // Prefer to direct the user back to the configuration page for hers/his
      // oauth server.
      navigate(`/configure/?hostname=${encodeURIComponent(authorizationEndpoint.origin)}`);
    } else {
      navigate('/');
    }

    // We should in principle never reach this point because of history push.
    // But it exists to make linters and TypeScript happy for the remaining parts of the function
    // @ts-ignore
    return {};
  }

  return {
    ...tokenConfiguration,
    // the useEffect check should verify that the client is authenticated,
    // before we return an access-token
    accessToken: tokenConfiguration.accessToken,
    rawResponse: tokenConfiguration.rawResponse!,
  };
}
