import React from 'react';
import AccessTokenExpirationNotification from '../components/AccessTokenExpirationNotification';
import JsonCodeBlock from '../components/ApiResponseCard/JsonCodeBlock';
import { Anchor, ButtonLink } from '../features/ui/link';
import OauthPlayFrame from '../features/ui/OauthPlayFrame';
import RedirectToConfigurationPage from '../features/openidconfiguration/RedirectToConfigurationPage';
import { HelpText } from '../features/ui/form';
import { PageTitle } from '../features/ui/text';
import useAccessToken from '../hooks/useAccessToken';
import { parseIdToken } from '../util/oauth/idtokenParser';

const PreviewAccessToken = () => {
  const { accessToken, expiresOn, rawResponse } = useAccessToken();
  if (!accessToken) {
    console.warn('User attempted to visit access token preview, but nothing exists');
    return <RedirectToConfigurationPage/>;
  }

  return <OauthPlayFrame>
    <AccessTokenExpirationNotification expiresOn={expiresOn}/>
    <section>
      <PageTitle>Response from server</PageTitle>

      <JsonCodeBlock json={rawResponse}/>
    </section>

    {rawResponse?.id_token && <section>
      <h2 className="block text-2xl mb-0 font-bold">Decoded ID Token</h2>
      <HelpText>
        More on ID tokens <Anchor href="https://www.oauth.com/oauth2-servers/openid-connect/id-tokens/" target="_blank" rel="noreferrer noopener">OAuth - ID Tokens</Anchor>
      </HelpText>

      <JsonCodeBlock json={parseIdToken(rawResponse.id_token)}/>
    </section>}

    <div className="mt-4">
      <ButtonLink className="block w-full" to="/userinfo">Fetch data</ButtonLink>
    </div>
  </OauthPlayFrame>;
};

export default PreviewAccessToken;
