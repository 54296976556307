import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface StandardHtmlElement {
  children: ReactNode;
  className?: string;
}

export const HelpText: React.FC<StandardHtmlElement> = ({ children, className = undefined }) => {
  return <p className={classNames('block text-xs m-0.5 mx-0', className)}>
    {children}
  </p>;
};

export const InputWrapper: React.FC<StandardHtmlElement> = ({ children, className = undefined }) => {
  return <div className={classNames('block m-1 mx-0 p-0', className)}>{children}</div>;
};

export const InputLabel: React.FC<React.LabelHTMLAttributes<any>> = ({ children, className = undefined, htmlFor }) => {
  return <label
    htmlFor={htmlFor}
    className={classNames('text-mine-shaft font-bold m-0 mb-[5px]', className)}
  >{children}</label>;
};
