import usePersistData from './usePersistData';

export interface OauthAuthenticationConfiguration {
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  userinfoEndpoint?: string;
  clientId?: string;
  scopes?: string;
  responseType: string;
}

const useOauthAuthenticationConfiguration = () => {
  const persistedConfiguration = usePersistData<OauthAuthenticationConfiguration>('oauthConfiguration');

  const configuration = persistedConfiguration.getPersistedData();
  return {
    configuration,
    saveConfiguration(configuration: OauthAuthenticationConfiguration) {
      persistedConfiguration.persist(configuration);
    },
    getBaseUrl(): string|undefined {
      if (!configuration?.authorizationEndpoint) {
        return undefined;
      }

      return new URL(configuration?.authorizationEndpoint).origin;
    }
  }
};

export default useOauthAuthenticationConfiguration;
