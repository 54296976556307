import React, { useEffect, useState } from 'react';
import OauthPlayFrame from '../features/ui/OauthPlayFrame';
import { PageTitle } from '../features/ui/text';
import { useRequiredAccessToken } from '../hooks/useAccessToken';
import useFetchUserData from '../hooks/useFetchUserData';
import useOauthAuthenticationConfiguration, { OauthAuthenticationConfiguration } from '../hooks/useOauthAuthenticationConfiguration';
import { getFeideApiUrls } from '../util/oauth/feideUserinfoUtil';
import ApiResponseCard from '../components/ApiResponseCard';
import UrlSelectForm from '../features/UrlSelectForm';
import { getOpenIdConnectUrls, hasOpenIdConnectScope } from '../util/oauth/openIdConnectHelper';
import { equalsHostname } from '../util/stringUtil';

const generateOAuthDataUrls = (configuration?: OauthAuthenticationConfiguration, tokenResponse?: Record<string, any>) => {
  if (!configuration || !tokenResponse) {
    return [];
  }

  const uris: { url: string, label: string }[] = [];

  // Inject feide options if the user has defined Feide OAuth endpoint
  if (equalsHostname(configuration.authorizationEndpoint, 'https://auth.dataporten.no/')) {
    console.info(`Identified configuration to be a Feide OAuth client. Injecting popular Feide api urls based on scopes: ${tokenResponse.scope}`);
    const scopes = tokenResponse.scope.split(' ');
    uris.push(...getFeideApiUrls(scopes));
  }

  if (hasOpenIdConnectScope(tokenResponse.scope)) {
    uris.push(...getOpenIdConnectUrls(configuration));
  }

  if (
    configuration.userinfoEndpoint
    && !uris.find(uri => uri.url === configuration.userinfoEndpoint)
  ) {
    uris.push({
      url: configuration.userinfoEndpoint,
      label: 'Basic userinfo',
    });
  }

  return uris;
};

const UserInfo = () => {
  const { configuration } = useOauthAuthenticationConfiguration();
  const { accessToken, rawResponse } = useRequiredAccessToken();
  const { fetchData, data: userData, error } = useFetchUserData(accessToken);

  const apiUris = generateOAuthDataUrls(configuration, rawResponse);
  const [selectedUri, setSelectedUri] = useState<string|undefined>(undefined);

  useEffect(() => {
    if (apiUris.length > 0 && !selectedUri) {
      setSelectedUri(apiUris[0].url)
    }
  }, [apiUris, selectedUri]);

  const onSubmit = () => {
    if (!selectedUri) {
      console.warn('No api uri has been selected. Skipping API call');
      return;
    }

    fetchData({ uri: selectedUri });
  };

  return <OauthPlayFrame>
    <section>
      <PageTitle>Retrieve userdata</PageTitle>

      <UrlSelectForm
        selected={selectedUri}
        values={apiUris}
        onChange={setSelectedUri}
        onSubmit={onSubmit}/>
    </section>

    <div className="mt-4">
      <ApiResponseCard
        requestUri={selectedUri}
        onUriChange={setSelectedUri}
        data={userData}
        error={error}/>
    </div>
  </OauthPlayFrame>;
};

export default UserInfo;
