import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const fetchData = (uri: string, accessToken: string): Promise<Record<string, any>> => axios
  .get(uri, { headers: { Authorization: `Bearer ${accessToken}` } })
  .then((res) => res.data);

/**
 * Utility hook used to fetch data from relevant OAuth endpoints
 * */
const useFetchUserData = (accessToken: string) => {
  const {
    isLoading,
    data,
    error,
    mutate,
  } = useMutation<Record<string, any>, Error, { uri: string }>(
    ['fetchuserdata', accessToken],
    ({ uri }) => fetchData(uri, accessToken),
    {
      onError(error, { uri }) {
        console.error(`Failed to retrieve user information from uri: ${uri}`, error);
      },
    },
  );

  return {
    isLoading,
    data,
    error: error ?? undefined,
    fetchData: mutate,
  };
};

export default useFetchUserData;
