import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { CustomLink } from '../../features/ui/link';
import useOauthAuthenticationConfiguration from '../../hooks/useOauthAuthenticationConfiguration';

const injectHostname = (hostname) => {
  if (!hostname) {
    return '';
  }

  return `?hostname=${encodeURIComponent(hostname)}`;
};

/**
 * Uses React router matchPath to check if a
 * route is active
 * */
const isCurrentRoute = (path, location) => !!matchPath(location.pathname, path);

export const OauthPlayPanel = () => {
  const location = useLocation();
  const { configuration } = useOauthAuthenticationConfiguration();
  const baseUrl = configuration?.authorizationEndpoint
    ? new URL(configuration.authorizationEndpoint).origin
    : undefined;

  const activePageStyles = 'font-bold cursor-default';

  return (
    <aside className="w-1/4 mb-8 block flex-none p-6 pl-10 pt-14">
      <ol type="1" className="list-decimal text-lg pl-7">
        <CustomLink
          to="/discover"
          className={isCurrentRoute('/discover', location) ? activePageStyles : undefined}
        >
          <li>Discover</li>
        </CustomLink>
        <CustomLink
          to={`/configure${injectHostname(baseUrl)}`}
          className={isCurrentRoute('/configure', location) ? activePageStyles : undefined}
        >
          <li>Configure</li>
        </CustomLink>
        <CustomLink
          to="/accesstoken"
          className={isCurrentRoute('/accesstoken', location) ? activePageStyles : undefined}
        >
          <li>Preview Access token</li>
        </CustomLink>
        <CustomLink
          to="/userinfo"
          className={isCurrentRoute('/userinfo', location) ? activePageStyles : undefined}
        >
          <li>Fetch data</li>
        </CustomLink>
      </ol>
    </aside>
  );
}

export default OauthPlayPanel;
