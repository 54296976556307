import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import { Anchor } from '../../features/ui/link';
import GenericErrorCard from '../GenericErrorCard';

/**
 * Outer application error boundrary to handle any uncatched events.
 *
 * Errors are reported to Sentry, with additional userinfo and tags
 * available.
 * */
export class OuterErrorBoundary extends React.Component<{ children: ReactNode }> {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      if (errorInfo) {
        scope.setExtras({ errorInfo });
      }
      Sentry.captureException(error);
    });
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    return <main className="my-8 mx-auto max-w-[50rem] p-8 w-full">
      <GenericErrorCard
        title="Something unexpected happened"
        message={<div>
          <p>
            Something unexpected happened and we failed to handle it silently. It is most likely a
            temporary issue and you should be able to try again later.
          </p>

          <p>
            <Anchor href="/">Go back to start</Anchor>
          </p>
        </div>}/>
    </main>;
  }
}

export default OuterErrorBoundary;
