import moment from 'moment/moment';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import OauthErrorFrame from '../components/OauthErrorFrame';
import useAccessToken from '../hooks/useAccessToken';
import useOauthAuthenticationConfiguration from '../hooks/useOauthAuthenticationConfiguration';

const hasError = error => error && !!error.title;
const hasNewAccessToken = (storedToken, receivedToken) => (
  !storedToken || storedToken !== receivedToken
);

function getErrorMessages(search) {
  if (!search || !search.error) {
    return null;
  }

  return {
    title: search.error,
    description: search.error_description || null,
    possibleSolutions: null, // Used to help users problem solve the error
    errorUri: search.error_uri || null,
    originalError: search,
  };
}

/**
 * Authorization-redirect endpoint.
 * Checks that the response is correct, or displays an error message,
 * and redirects to preview the access token.
 * */
const CallbackPage = () => {
  const location = useLocation();
  const { configuration } = useOauthAuthenticationConfiguration();
  const accessToken = useAccessToken();

  const hash = qs.parse(location.hash.replace('#', ''));
  const search = qs.parse(location.search.replace('?', ''));
  // Combine all potential params since some identity provider
  // can return both hash parameter and search parameters
  const commonParams = Object.assign(hash, search);

  const error = getErrorMessages(commonParams);

  if (!hash || Object.keys(hash).length < 1) {
    console.error('Didn\'t receive any data from the OAuth authorization endpoint. Location:', location);
  } else if (!hasError(error) && hasNewAccessToken(accessToken.accessToken, hash.access_token)) {
    const expiresOn = moment().add(hash.expires_in, 'seconds');
    accessToken.saveToken(hash.access_token, expiresOn, commonParams);
  }

  const authorizationEndpoint = configuration?.authorizationEndpoint ? new URL(configuration.authorizationEndpoint) : undefined;

  if (error) {
    return <OauthErrorFrame
      returnUrl={{
        label: 'Back to configuration',
        url: `/configure?hostname=${authorizationEndpoint?.origin ?? ''}`,
      }}
      header="Could not Authorize client"
      error={error}
    />;
  }

  return <Navigate to="/accesstoken"/>;
};

export default CallbackPage;
