import React from 'react';

const OauthPlayFrame: React.FC<React.HTMLProps<any>> = ({ children }) => (
  <div className="m-4 max-w-[50rem] shadow-2xl">
    <div className="bg-alabaster p-8">
      {children}
    </div>
  </div>
);

export default OauthPlayFrame;
