import classNames from 'classnames';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

const defaultLinkStyles = 'cursor-pointer text-mine-shaft transition-all ease-in decoration-0 border-b-water border-b-2 border-solid border-2' +
  'hover:text-mine-shaft hover:border-b-corn-flower hover:decoration-0 ' +
  'focus:text-mine-shaft focus:border-b-corn-flower ' +
  'active:text-mine-shaft active:border-b-corn-flower';

/**
 * Identical to react-router-dom, but applies our custom styles.
 * */
export const CustomLink: React.FC<LinkProps> = (props) => {
  return <Link
    {...props}
    className={classNames(defaultLinkStyles, props.className)}
  >{props.children}</Link>;
};

export const ButtonLink: React.FC<LinkProps> = (props) => {
  return <Link
    {...props}
    className={classNames('p-2 px-4 cursor-pointer text-gallery text-center bg-matisse border-none hover:text-gallery', props.className)}
  >{props.children}</Link>;
}

/**
 * Use when linking to external sites, or you need to conduct a request to a server
 * */
export const Anchor: React.FC<React.AnchorHTMLAttributes<any>> = (props) => {
  return <a
    {...props}
    className={classNames(defaultLinkStyles, props.className)}
  >{props.children}</a>;
};
