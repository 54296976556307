import * as Sentry from '@sentry/browser';

const sentryDsn = 'https://4fefdef555d744bfbc256db1e8313190@sentry.feide.no/4';

const identifySentryEnvironment = () => {
  if (!window || !window.location) {
    return undefined;
  }

  const { hostname } = window.location;
  if (hostname === 'play.oauth.no') {
    return 'production';
  }

  if (hostname.startsWith('play-oauth-staging')) {
    return 'staging';
  }

  if (hostname.startsWith('play-oauth-review')) {
    return 'review';
  }

  return 'development';
};

/**
 * @var {Sentry}
 * */
let sentryInstance = null;

const isProd = () => {
  const prod = identifySentryEnvironment() === 'production';

  if (!prod) {
    console.info('%c [Sentry] Application not running in productions. Errors will not be reported to sentry.', 'color: #FF830F; font-weight: bold');
  }

  return prod;
};

// eslint-disable-next-line no-undef
const initSentry = () => {
  const dsn = sentryDsn;
  const environment = identifySentryEnvironment();

  Sentry.init({
    dsn,
    environment,
    enabled: !!environment && environment !== 'development',
  });

  return Sentry;
};

// eslint-disable-next-line import/prefer-default-export
export const getSentry = () => {
  if (!isProd()) {
    return null;
  }

  if (!sentryInstance) {
    sentryInstance = initSentry();
  }

  return sentryInstance;
};
