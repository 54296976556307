import React from 'react';
import { useNavigate } from 'react-router-dom';
import { buildConfigureUrl } from '../../features/openidconfiguration/RedirectToConfigurationPage';
import useAccessToken from '../../hooks/useAccessToken';
import useOauthAuthenticationConfiguration from '../../hooks/useOauthAuthenticationConfiguration';
import Logo from './Logo';
import StatusPanel from './StatusPanel';

export const Header = () => {
  const accessToken = useAccessToken();
  const { configuration } = useOauthAuthenticationConfiguration();
  const navigate = useNavigate();

  const clearToken = () => {
    accessToken.clearToken();
    const redirectUri = buildConfigureUrl(configuration?.authorizationEndpoint) ?? '/discover';
    navigate(redirectUri)
  }

  return (
    <header className="block bg-gallery relative w-full">
      <div className="m-auto max-w-6xl w-full flex flex-wrap items-stretch justify-between p-8 px-6">
        <Logo/>

        <StatusPanel
          hasAccessToken={!!accessToken}
          expiresOn={accessToken.expiresOn}
          onClearToken={clearToken}
        />
      </div>
    </header>
  );
}

export default Header;
